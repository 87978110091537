export const SERVICE_TYPES = {
    SERVICE_CUSTOM: "CUSTOM",
    SERVICE_CUSTOM_RECURRING: "CUSTOM_RECURRING",
    SERVICE_STORAGE_BY_LOCATION: "STORAGE_BY_LOCATION",
    SERVICE_STORAGE_BY_PRODUCT: "STORAGE_BY_PRODUCT",
    SERVICE_SHIPPING_LABEL: "SHIPPING_LABEL",
    SERVICE_SHIPPING_LABEL_EXTENDED_ZONE: "SHIPPING_LABEL_EXTENDED_ZONE",
    SERVICE_INTERNATIONAL_SHIPPING_LABEL: "INTERNATIONAL_SHIPPING_LABEL",
    SERVICE_SHIPPING_LABEL_PICKUP: "SHIPPING_LABEL_PICKUP",
    SERVICE_RETURN_ORDER: "RETURN_ORDER",
    SERVICE_RETURN_ORDER_LOTS: "RETURN_ORDER_LOTS",
    SERVICE_RETURN_SHIPPING_LABEL: "RETURN_SHIPPING_LABEL",
    SERVICE_PICK_AND_PACK: "PICK_AND_PACK",
    SERVICE_PICK_AND_PACK_INSERT: "PICK_AND_PACK_INSERT",
    SERVICE_PICK_AND_PACK_SERIAL_NUMBER: "PICK_AND_PACK_SERIAL_NUMBER",
    SERVICE_PICK_AND_PACK_FRAGILE: "PICK_AND_PACK_FRAGILE",
    SERVICE_PICK_AND_PACK_PACKAGE: "PICK_AND_PACK_PACKAGE",
    SERVICE_REPLENISHMENT: "REPLENISHMENT",
    SERVICE_REPLENISHMENT_LOTS: "REPLENISHMENT_LOTS",
    SERVICE_CLAIM: "CLAIM",
    SERVICE_WORK_ORDER: "WORK_ORDER",
    SERVICE_DIFAL: "DIFAL",
    SERVICE_SHIPPING_INSURANCE: "SHIPPING_INSURANCE",
    SERVICE_PACKING_MATERIAL: "PACKING_MATERIAL"
}

export const STORAGE_BILLING_TYPES = {
    SERVICE_STORAGE_BY_LOCATION: "STORAGE_BY_LOCATION",
    SERVICE_STORAGE_BY_PRODUCT: "STORAGE_BY_PRODUCT"
}

export const PACKAGE_SERVICE_TYPE_CATEGORIES = [
    "parcel_bag", "jiffy_envelope", "cubbo_box", "customer_box"
]

export const RANGE_EXCLUSION_MODES = {
    RANGE_EXCLUDE_FROM: 'EXCLUDE_FROM',
    RANGE_EXCLUDE_TO: 'EXCLUDE_TO'
}

export const PRICE_MODES = {
    PRICE_MODE_PER_UNIT: 'PER_UNIT',
    PRICE_MODE_TOTAL: 'TOTAL'
}

export const NATIONAL_MX_DEPRECATED_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES = {
    SUPER_SAVER: "SUPER_SAVER",
    LOCAL: "LOCAL",
    PREMIUM: "PREMIUM",
    ECONOMY: "ECONOMY"
}

export const NATIONAL_MX_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES = {
    SAVER_PRINCIPAL_CITIES: "SAVER_PRINCIPAL_CITIES",
    SAVER_NATIONAL: "SAVER_NATIONAL",
    SAVER_LOCAL: "SAVER_LOCAL",
    CUBBO_PRINCIPAL_CITIES: "CUBBO_PRINCIPAL_CITIES",
    CUBBO_NATIONAL: "CUBBO_NATIONAL",
    CUBBO_LOCAL: "CUBBO_LOCAL",
    GROUND_PRINCIPAL_CITIES: "GROUND_PRINCIPAL_CITIES",
    GROUND_NATIONAL: "GROUND_NATIONAL",
    GROUND_LOCAL: "GROUND_LOCAL",
    EXPRESS_PRINCIPAL_CITIES: "EXPRESS_PRINCIPAL_CITIES",
    EXPRESS_NATIONAL: "EXPRESS_NATIONAL",
    EXPRESS_LOCAL: "EXPRESS_LOCAL"
}

export const NATIONAL_BR_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES = {
    SP_CAP: "SP_CAP",
    SP_INT: "SP_INT",
    RJ_CAP: "RJ_CAP",
    RJ_INT: "RJ_INT",
    ES_CAP: "ES_CAP",
    ES_INT: "ES_INT",
    MG_CAP: "MG_CAP",
    MG_INT: "MG_INT",
    BA_CAP: "BA_CAP",
    BA_INT: "BA_INT",
    SE_CAP: "SE_CAP",
    SE_INT: "SE_INT",
    PE_CAP: "PE_CAP",
    PE_INT: "PE_INT",
    AL_CAP: "AL_CAP",
    AL_INT: "AL_INT",
    PB_CAP: "PB_CAP",
    PB_INT: "PB_INT",
    RN_CAP: "RN_CAP",
    RN_INT: "RN_INT",
    CE_CAP: "CE_CAP",
    CE_INT: "CE_INT",
    PI_CAP: "PI_CAP",
    PI_INT: "PI_INT",
    MA_CAP: "MA_CAP",
    MA_INT: "MA_INT",
    PA_CAP: "PA_CAP",
    PA_INT: "PA_INT",
    AP_CAP: "AP_CAP",
    AP_INT: "AP_INT",
    AM_CAP: "AM_CAP",
    AM_INT: "AM_INT",
    RR_CAP: "RR_CAP",
    RR_INT: "RR_INT",
    AM_INT_2: "AM_INT_2",
    AC_CAP: "AC_CAP",
    AC_INT: "AC_INT",
    DF_CAP: "DF_CAP",
    DF_INT: "DF_INT",
    GO_CAP: "GO_CAP",
    DF_INT_2: "DF_INT_2",
    GO_CAP_2: "GO_CAP_2",
    GO_INT: "GO_INT",
    RO_CAP: "RO_CAP",
    RO_INT: "RO_INT",
    TO_CAP: "TO_CAP",
    TO_INT: "TO_INT",
    MT_CAP: "MT_CAP",
    MT_INT: "MT_INT",
    RO_INT_2: "RO_INT_2",
    RO_INT_3: "RO_INT_3",
    MS_CAP: "MS_CAP",
    MS_INT: "MS_INT",
    PR_CAP: "PR_CAP",
    PR_INT: "PR_INT",
    SC_CAP: "SC_CAP",
    SC_INT: "SC_INT",
    RS_CAP: "RS_CAP",
    RS_INT: "RS_INT"
}

export const INTERNATIONAL_SHIPPING_LABELS_SERVICE_TYPE_CATEGORIES = {
    NORTH_AMERICA: "NORTH_AMERICA",
    LATIN_AMERICA: "LATIN_AMERICA",
    CARIBBEAN: "CARIBBEAN",
    EUROPE: "EUROPE",
    ASIA: "ASIA",
    AFRICA: "AFRICA"
}

export const INTERNATIONAL_SHIPPING_LABELS_RANGES = {
    INTERNATIONAL_1: 36,
    INTERNATIONAL_2: 10
}

export const CONFIG_CUTOFF_INTERVALS = {
    // INTERVAL_YEARLY: "YEAR",
    INTERVAL_MONTHLY: "MONTH",
    INTERVAL_WEEKLY: "WEEK"
}

export const CONFIG_CUTOFF_MODES = {
    CUTOFF_MODE_INCREMENT_BY_INTERVAL: "INCREMENT_BY_INTERVAL",
    CUTOFF_MODE_END_OF_INTERVAL: "END_OF_INTERVAL"
}

export const PACKING_MATERIAL_CODES = [
    'KRAFT_PAPER', 'BUBBLE_WRAP', 'CORN_EXTRUDATE'
]
